import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  EducationRateMasterData,
  ExerciseLargeResource,
  ExerciseManagerCardResource,
  ExerciseMasterResource,
  ExerciseStore,
  ExerciseMastersTable,
  ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidDeleteRequest,
  ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidFormsGetRequest,
  ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidGetRequest,
  ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidPostRequest,
  ManagerMasterGroupsMasterGroupIdExercisesGetParams,
  ManagerMasterGroupsMasterGroupIdExercisesGetRequest,
  ManagerMasterGroupsMasterGroupIdExercisesPostRequest,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersGetRequest,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdCompletePostRequest,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdGetParams,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdGetRequest,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesGetRequest,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesPostRequest,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdRatePostRequest,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdSendKeysPostRequest,
  ProgressResourcePaginator,
  MessageResource,
  MessageStore,
  ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidFormsGetParams,
  ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidTakeMasterIdPostRequest,
  ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidRefuseMasterIdPostRequest,
  MistakeShortResource,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMistakesGetRequest,
  ExerciseStatusTypeTable,
  ExerciseStatusSpecialGroupTypeTable,
  EducationLargeTaskResource,
  TaskDraftStoreData,
  DraftsTaskTaskUuidMasterIdPostRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetRequest,
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersShortGetRequest,
  MasterWhoCompleteTaskResource,
  DraftsTaskTaskUuidMasterIdGetRequest,
  DraftsTaskTaskUuidMasterIdRatePostRequest,
  TaskRateDraftStoreData, DraftsTaskTaskUuidMasterIdRateGetRequest,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'
import { timeout } from '@/utils/constants'

/**
 * Домашки ментора
 * - домашки
 */

@Module({
  dynamic: true,
  name: 'MentorExercises',
  namespaced: true,
  store,
})
class MentorExercises extends VuexModule {
  // ---------------------------- Exercises ---------------------------- >>
  exercises: ExerciseManagerCardResource[] = []
  exercisesFilter: ManagerMasterGroupsMasterGroupIdExercisesGetParams = {}

  @Mutation
  setExercises (payload: ExerciseManagerCardResource[]) {
    this.exercises = [...payload]
  }

  @Mutation
  setExercisesFilter(payload: ManagerMasterGroupsMasterGroupIdExercisesGetParams) {
    this.exercisesFilter = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchExercises (payload: { masterGroupId: number, params: ManagerMasterGroupsMasterGroupIdExercisesGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdExercisesGetRequest(payload.masterGroupId, payload.params)
    this.setExercises(data)
    return data
  }

  @Action({ rawError: true })
  async fetchMentorExercises (payload: { masterGroupId: number, params: ManagerMasterGroupsMasterGroupIdExercisesGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdExercisesGetRequest(payload.masterGroupId, payload.params)
    return data
  }

  // ---------------------------- Exercise ---------------------------- >>
  exercise: ExerciseLargeResource | null = null

  @Mutation
  setExercise (payload: ExerciseLargeResource | null) {
    this.exercise = payload
  }

  @Action({ rawError: true })
  async fetchExercise (payload: { masterGroupID: number, exerciseUUID: string }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidGetRequest(payload.masterGroupID, payload.exerciseUUID)
    this.setExercise(data)
    return data
  }

  @Action({ rawError: true })
  async fetchExerciseQuickStart (payload: { masterGroupID: number, exerciseUUID: string }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidGetRequest(payload.masterGroupID, payload.exerciseUUID)
    this.setExercise(data)
    return data
  }

  // Сохранение индивидуального задания в группе MG Plus
  @Action({ rawError: true })
  async saveExercise (payload: { exerciseUUID?: string, masterGroupID: number, params: ExerciseStore }) {
    if (payload.exerciseUUID) {
      const { data } = await ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidPostRequest(payload.masterGroupID, payload.exerciseUUID, payload.params)
      this.setExercise(data)
      return data
    } else {
      const { data } = await ManagerMasterGroupsMasterGroupIdExercisesPostRequest(payload.masterGroupID, payload.params)
      this.setExercise(data)
      return data
    }
  }

  // Удаление индивидуального задания в группе MG Plus
  @Action({ rawError: true })
  async deleteExercise (payload: { exerciseUUID: string, masterGroupID: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidDeleteRequest(payload.masterGroupID, payload.exerciseUUID)
    this.setExercise(null)
    return data
  }

  // ---------------------------- Task masters ---------------------------- >>
  masters: ExerciseMasterResource[] = []
  currentMasterID: number | null = null
  exerciseStatusFilter: ExerciseStatusTypeTable = null
  mastersFilter: any = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }
  mistakes: MistakeShortResource[] = []
  task: EducationLargeTaskResource | null = null
  neighbourMasters: {list: MasterWhoCompleteTaskResource[] | null, groupId: number|null, taskUUID: string|null} = {
    groupId: null,
    list: null,
    taskUUID: null,
  }

  @Mutation
  setTask(payload: EducationLargeTaskResource) {
    this.task = Object.assign({}, payload)
  }

  @Mutation
  setNeighbourMasters(payload: {
    data: MasterWhoCompleteTaskResource[],
    masterGroupId: number,
    taskUUID: string,
  }) {
    this.neighbourMasters.list = payload.data
    this.neighbourMasters.groupId = payload.masterGroupId
    this.neighbourMasters.taskUUID = payload.taskUUID
  }

  @Mutation
  setContentTask(payload: string) {
    if (this.task)
      this.task = Object.assign(this.task, { content: payload })
  }

  @Mutation
  setHashTask(payload: string) {
    if (this.task)
      this.task = Object.assign(this.task, { draftHash: payload })
  }

  @Mutation
  setMasters (payload: ExerciseMasterResource[]) {
    this.masters = [...payload]
  }

  @Mutation
  setExerciseStatusFilter(payload: ExerciseStatusTypeTable) {
    this.exerciseStatusFilter = payload
  }

  @Mutation
  setMastersFilter(payload: any) {
    this.mastersFilter = Object.assign({}, payload)
  }

  @Mutation
  setMistakes(payload: MistakeShortResource[]) {
    this.mistakes = payload
  }

  @Mutation
  setCurrentMasterID(payload: number | null) {
    this.currentMasterID = payload
  }

  @Action({ rawError: true })
  async fetchMasters (payload: { masterGroupID: number, taskUUID: string }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersGetRequest(payload.masterGroupID, payload.taskUUID)
    this.setMasters(data)
    return data
  }

  // ---------------------------- Task master ---------------------------- >>
  @Action({ rawError: true })
  async fetchMasterTask (payload: { masterGroupID: number, taskUUID: string, masterID: number, params?: ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdGetRequest(payload.masterGroupID, payload.taskUUID, payload.masterID, payload.params)
    this.setTask(data)
    return data
  }

  @Action({ rawError: true })
  async fetchNeighbourMasters (payload: { masterGroupID: number, taskUUID: string }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersShortGetRequest(
      payload.masterGroupID,
      payload.taskUUID,
    )
    this.setNeighbourMasters({
      data: data,
      masterGroupId: payload.masterGroupID,
      taskUUID: payload.taskUUID,
    })
    return data
  }

  @Action({ rawError: true })
  async saveDraftTask(payload: { body: TaskDraftStoreData, masterId: number, taskUuid: string }) {
    const res = await DraftsTaskTaskUuidMasterIdPostRequest(payload.taskUuid, payload.masterId, payload.body, { loading: false, timeout })
    if (this.currentMasterID === payload.masterId) {
      if (this.task && this.task.uuid === payload.taskUuid) {
        this.setContentTask(res.data.content)
        this.setHashTask(res.data.hash)
      }
    }
    return res
  }

  @Action({ rawError: true })
  async fetchDraftTask(payload: { masterId: number, taskUuid: string }) {
    const { data } = await DraftsTaskTaskUuidMasterIdGetRequest(payload.taskUuid, payload.masterId, { loading: false })
    if (this.currentMasterID === payload.masterId) {
      if (this.task && this.task.uuid === payload.taskUuid) {
        this.setHashTask(data.hash)
      }
    }
    return data
  }

  @Action({ rawError: true })
  async fetchMistakes(payload: { masterGroupID: number, taskUUID: string, masterID: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMistakesGetRequest(payload.masterGroupID, payload.taskUUID, payload.masterID)
    this.setMistakes(data)
    return data
  }

  // ---------------------------- Task manage ---------------------------- >>
  @Action({ rawError: true })
  async sendKey (payload: { masterGroupID: number, taskUUID: string, masterID: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdSendKeysPostRequest(payload.masterGroupID, payload.taskUUID, payload.masterID)
    return data
  }

  @Action({ rawError: true })
  async rateMasterTask (payload: { masterGroupID: number, taskUUID: string, masterID: number, params: EducationRateMasterData }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdRatePostRequest(payload.masterGroupID, payload.taskUUID, payload.masterID, payload.params)
    return data
  }

  @Action({ rawError: true })
  async completeMasterTask (payload: { masterGroupID: number, taskUUID: string, masterID: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdCompletePostRequest(payload.masterGroupID, payload.taskUUID, payload.masterID)
    return data
  }

  // ---------------------------- Task messages ---------------------------- >>
  messages: MessageResource[] = []

  @Mutation
  setMessages (payload: MessageResource[]) {
    this.messages = [...payload]
  }

  @Mutation
  unsetMessages() {
    this.messages = []
  }

  @Mutation
  addMessage (payload: MessageResource) {
    this.messages.push(payload)
  }

  @Action({ rawError: true })
  async fetchMessages (payload: { masterGroupID: number, taskUUID: string, masterID: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesGetRequest(payload.masterGroupID, payload.taskUUID, payload.masterID)
    this.setMessages(data)
    return data
  }

  @Action({ rawError: true })
  async sendMessage (payload: { masterGroupID: number, taskUUID: string, masterID: number, params: MessageStore }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesPostRequest(payload.masterGroupID, payload.taskUUID, payload.masterID, payload.params)
    this.addMessage(data)
    return data
  }

  // ---------------------------- Masters and Exercises - Special Group ---------------------------- >>
  mastersSpecialGroup: ProgressResourcePaginator = GET_DEFAULT_PAGINATOR()
  masterExerciceStatusSpecialGroup: ExerciseStatusSpecialGroupTypeTable = null
  masterSpecialGroupFilter: ExerciseMastersTable = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
  }

  @Mutation
  setMastersSpecialGroup(payload: ProgressResourcePaginator) {
    this.mastersSpecialGroup = { ...payload }
  }

  @Mutation
  setMastersSpecialGroupFilter(payload: ExerciseMastersTable) {
    this.masterSpecialGroupFilter = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchMastersSpecialGroup(payload: { masterGroupId: number, exerciseUuid: string, filter: ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidFormsGetParams }): Promise<ProgressResourcePaginator> {
    const { data } = await ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidFormsGetRequest(payload.masterGroupId, payload.exerciseUuid, tableOptionsToParams(payload.filter))
    this.setMastersSpecialGroup(data)
    return data
  }

  @Action({ rawError: true })
  async takeExerciseInWork(payload: { masterGroupId: number, exerciseUuid: string, masterId: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidTakeMasterIdPostRequest(payload.masterGroupId, payload.exerciseUuid, payload.masterId)
    return data
  }

  @Action({ rawError: true })
  async removeExerciseFromWork(payload: { masterGroupId: number, exerciseUuid: string, masterId: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdExercisesExerciseUuidRefuseMasterIdPostRequest(payload.masterGroupId, payload.exerciseUuid, payload.masterId)
    return data
  }

  @Action({ rawError: true })
  async fetchMaster(payload: { masterGroupID: number, masterUUID: number, monthID?: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetRequest(payload.masterGroupID, payload.masterUUID, { healthOnly: true, monthId: payload.monthID })
    return data
  }

  // ---------------------------- Autosave Rate Exercise ---------------------------- >>
  @Action({ rawError: true })
  async saveDraftRateTask(payload: { body: TaskRateDraftStoreData, masterID: number, taskUUID: string }) {
    const { data } = await DraftsTaskTaskUuidMasterIdRatePostRequest(payload.taskUUID, payload.masterID, payload.body, { loading: false })
    // if (this.currentMasterID === payload.masterID) {
    //   if (this.task && this.task.uuid === payload.taskUUID) {
    //     this.setHashTask(data.hash)
    //   }
    // }
    return data
  }

  @Action({ rawError: true })
  async fetchDraftRateTask(payload: { masterID: number, taskUUID: string }) {
    const { data } = await DraftsTaskTaskUuidMasterIdRateGetRequest(payload.taskUUID, payload.masterID)
    return data
  }
}

const MentorExercisesModule = getModule(MentorExercises)

export default MentorExercisesModule
